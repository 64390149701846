<template>
    <v-main>
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card>
              <v-container fluid>
                <v-card-title v-if="!exercise_id">{{'Add Exercise' | translate}}</v-card-title>
                <v-card-title v-if="exercise_id">{{'Edit Exercise' | translate}}</v-card-title>
                <v-card-text style="position: relative">
                  <v-form id="form" ref="form">
                    <v-row>
                      <v-col cols="12" sm="8">
                        <v-row v-if="errors">
                          <v-col cols="12" sm="12" md="12" v-if="Array.isArray(errors)">
                            <v-alert
                              dense
                              v-for="(v, k) in errors"
                              :key="k"
                              outlined
                              type="error"
                            >{{ k }} {{ v }}</v-alert>
                          </v-col>
                          <v-col cols="12" sm="12" md="12" v-else>
                            <v-alert
                              dense
                              outlined
                              type="error"
                            >{{ errors }}</v-alert>
                          </v-col>
                        </v-row>
  
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              name="exercise_name"
                              outlined
                              v-model="exercise_name"
                              :rules="exercise_nameRules"
                              :label="'Exercise Name' | translate"
                              required
                            >
                              <template #message="{ message }">
                                {{ message | translate }}
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              outlined
                              name="exercise_description"
                              v-model="exercise_description"
                              :label="'Description' | translate"
                              :rules="certificatedescriptionRules"
                            >
                              <template #message="{ message }">
                                {{ message | translate }}
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
  
  
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-select
                              v-model="exercise_language"
                              :items="languages"
                              item-value="lang_code"
                              item-text="lang_name"
                              autocomplete="off"
                              name="exercise_language"
                              outlined
                              :label="'Language' | translate"
                              :rules="languageRules"
                              required
                            >
                              <template #message="{ message }">
                                {{ message | translate }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select
                              v-model="author_name"
                              :items="authors"
                              :rules="authornamerequiredRules"                            
                              item-value="author_id"
                              item-text="author_name"
                              :label="'Author' | translate"
                              autocomplete="off"
                              name="author_name"
                              multiple
                              outlined
                              required
                            >
                              <template #message="{ message }">
                                {{ message | translate }}
                              </template>
                            </v-select> 
                          </v-col>
                        </v-row>
  
                        <v-row>
                          <v-col cols="12" sm="12">
                            <h3 class="my-3">{{'First Page Instructions' | translate}}</h3>
                            <tiptap-vuetify name="first_page_description"
                              v-model="first_page_description" 
                              :extensions="extensions"  />
                          </v-col>
                        </v-row>
  
                        <v-row class="sub-section">  
                          <v-col cols="12" sm="12">
                            <v-row>
                              <v-col cols="12" sm="12">
                                <h3 class="my-3">{{'Exercise Instructions' | translate}}</h3>
                                <tiptap-vuetify name="exercise_instruction"
                                  v-model="exercise_instruction"
                                  :extensions="extensions"  />
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="9" sm="9">
                                <h3 class="my-3">{{'Add resource' | translate}}</h3>
                                <v-file-input
                                  @change="onResourceSelected"
                                  id="resource"
                                  outlined
                                  chips
                                  :rules="resourceRules"
                                  :label="'Upload resource' | translate"
                                >
                                  <template #message="{ message }">
                                    {{ message | translate }}
                                  </template>
                                </v-file-input>
                              </v-col>

                              <v-col v-if="resourceurl" cols="3" sm="3">
                                <v-img
                                  :src="resourceurl"
                                  aspect-ratio="1"
                                  class="grey lighten-2"
                                  max-width="500"
                                  max-height="300"
                                ></v-img>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
  
                        <v-row>
                          <v-col cols="12" sm="12">
                            <h3 class="my-3">{{'Instruction on Upload' | translate}}</h3>
                            <tiptap-vuetify name="instruction_on_upload"
                              v-model="instruction_on_upload"
                              :extensions="extensions"  />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" sm="12">
                            <h3 class="my-3">{{'Instruction on Grading' | translate}}</h3>
                            <tiptap-vuetify name="instruction_on_grade"
                              v-model="instruction_on_grade"
                              :extensions="extensions"  />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" sm="12">
                            <h3 class="my-3">{{'Result Page Instructions' | translate}}</h3>
                            <tiptap-vuetify name="result_description"
                              v-model="result_description"
                              :extensions="extensions"  />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" sm="12">
                            <h3 class="my-3">{{'Result Page Instructions for Admin while grading' | translate}}</h3>
                            <tiptap-vuetify name="last_page_details_for_admin"
                              v-model="last_page_details_for_admin"
                              :extensions="extensions"  />
                          </v-col>
                        </v-row>

                        
  
                      </v-col>
  
                      <v-col cols="12" sm="4">
                        <v-row>
                          <v-col v-if="url" cols="12" sm="12">
                            <v-img
                              :src="url"
                              aspect-ratio="1"
                              class="grey lighten-2"
                              max-width="500"
                              max-height="300"
                            ></v-img>
                          </v-col>
  
                          <v-col cols="12" sm="12">
                            <v-file-input
                              @change="oneImageSelected"
                              id="exercise_image"
                              accept="image/*"
                              outlined
                              chips
                              :rules="exerciseImageRules"
                              :label="'Exercise Image' | translate"
                            >
                              <template #message="{ message }">
                                {{ message | translate }}
                              </template>
                            </v-file-input>
                          </v-col>
                      
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
  
                  <v-btn
                    dark
                    fab
                    fixed
                    bottom
                    right
                    large
                    color="pink"
                    class="mx-2"
                    @click="submit"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </v-card-text>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </template>
  
  <script>
  import {
    ADD_EXERCISE,
    FETCH_AUTHORS,
    FETCH_LANGUAGES,
    EXERCISE_RESET_STATE,
    FETCH_INDIVIDUAL_EXERCISE,
  } from "@/store/actions.type"
  import { SET_LOADING, SET_SNACKBAR } from "@/store/mutations.type";
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
  import store from "@/store";
  import { mapGetters } from "vuex";
  import { mapState } from "vuex";
  
  export default {
    name: "addeditModules", 
    async beforeRouteUpdate(to, from, next) {
      Promise.all([
      await store.dispatch(EXERCISE_RESET_STATE),
      ])
      return next();
    },
    async beforeRouteEnter(to, from, next) {
      Promise.all([
      await store.dispatch(EXERCISE_RESET_STATE),
      await store.dispatch(FETCH_LANGUAGES,''),
      await store.dispatch(FETCH_AUTHORS),
      ]).finally(() => {
        store.commit(SET_LOADING, false)
      })
      if (to.params.id) {
        await store.dispatch(FETCH_INDIVIDUAL_EXERCISE, to.params.id).then(()=> {
            store.commit(SET_LOADING, false)
          })
      }
      return next();
    },
    async beforeRouteLeave(to, from, next) {
      this.routechange = 'changed'
      Promise.all([
      await store.dispatch(EXERCISE_RESET_STATE),
      ])
  
      next();
    },
    components: { TiptapVuetify },    
    data: function() {
      return {
        exercise_name: null,
        exercise_description: '',
        exercise_language: null,
        routechange: 'notchanged',
        author_name: [],
        first_page_description: null,
        exercise_instruction: null,
        result_description: null,
        last_page_details_for_admin: null,
        instruction_on_upload: null,
        instruction_on_grade: null,
        description: null,
        long_description: null,
        moduletypes: [],
        module_versions: [],
        upload_module_file: false,
        exercise_id: null,
        exercise_image: "",
        resource: "",
        modulefile: "",
        moduletype: null,
        moduledata: {},
        url: "",
        resourceurl:"",
        active: null,
        dialog: false,     
        versions: [{ name: "1" }, { name: "2" }],
        extensions: [
          [Heading, {
            options: {
              levels: [1, 2, 3,4]
            }
          }],
          Paragraph, 
          BulletList,
          OrderedList,                
          Bold,
          Italic,
          Underline,                       
          Blockquote,        
          Link,
          Strike,
          ListItem,
          HorizontalRule,
          HardBreak,
          History,        
        ],
        exercise_nameRules: [v => !!v || "Exercise Name is required"],
        abbreviationRules: [v => !!v || "Abbreviation is required"],
        certificatedescriptionRules: [v => !!v || "Exercise description is required"],
        languageRules: [v => !!v || "Language is required"],
        authornamerequiredRules: [v => !!v || "Author name is required"],
        aboutAuthorRules: [v => !!v || "Author Description is required"],
        exerciseImageRules: [
          v => !!v || 'Exercise Image is required',
          v => (v && v.size > 0) || 'Exercise Image is required',
        ],
        resourceRules: [
          v => !!v || 'Resource is required',
          v => (v && v.size > 0) || 'Resource is required',
        ],
      };
    },
    computed: {
      ...mapGetters(['authors', 'individualexercise', 'languages', 'loadingdata']),
      ...mapState({
        errors: state => state.exercises.exerciseerrors
      })
    },
    created() {
      if (Object.keys(this.individualexercise).length > 0) {
        this.exercise_id = this.individualexercise.exercise_id
        this.exercise_name = this.individualexercise.exercise_name
        this.exercise_description = this.individualexercise.exercise_description
        this.exercise_language = this.individualexercise.exercise_language
        this.first_page_description = this.individualexercise.first_page_details
        this.exercise_instruction = this.individualexercise.middle_page_details
        this.result_description = this.individualexercise.last_page_details
        this.last_page_details_for_admin = this.individualexercise.last_page_details_for_admin
        this.instruction_on_upload = this.individualexercise.instruction_on_upload
        this.instruction_on_grade = this.individualexercise.instruction_on_grade
        
        let authordata = []
        this.individualexercise.authors.forEach(function(item) {
          authordata.push(item.author_id)
        });
        
        this.author_name = authordata
      }
    },
    methods: {
      oneImageSelected() {
        this.exercise_image = document.getElementById("exercise_image").files[0];
        this.url = URL.createObjectURL(this.exercise_image);
      },
      onResourceSelected() {
        this.resourceurl = null;
        this.resource = document.getElementById("resource").files[0];
        if(/^image\//.test(document.getElementById("resource").files[0].type)){
          this.resourceurl = URL.createObjectURL(this.resource);
        }
      },
      onFileSelected() {
        this.modulefile = document.getElementById("module_file").files[0];
      },
      openModuleFileDialog() {
        this.dialog = true
      },
      submit: function() {
        if (this.$refs.form.validate()) {
          var formData = new FormData(document.getElementById("form"));
          formData.set("exercise_name", this.exercise_name)
          formData.set("exercise_description", this.exercise_description)
          formData.append("exercise_image", this.exercise_image)
          formData.append("exercise_template", this.resource)
          formData.set("exercise_language", this.exercise_language)
          formData.set("first_page_details", this.first_page_description)
          formData.set("last_page_details", this.result_description)
          formData.set("last_page_details_for_admin", this.last_page_details_for_admin)
          formData.set("instruction_on_upload", this.instruction_on_upload)
          formData.set("instruction_on_grade", this.instruction_on_grade)
          formData.set("middle_page_details", this.exercise_instruction)
          this.author_name.forEach(function(item) {
            formData.append("authors[]", item);
          });
          this.$store.dispatch(ADD_EXERCISE, formData).then(resp => {
            if (resp !==undefined && resp.success) {
              this.$router.push({
                name: "exercises",
              });
              store.commit(SET_SNACKBAR, {status: true, message: 'Exercise created successfully'})
            }
            else if (resp !==undefined && !resp.success) {
              store.commit(SET_LOADING, false)
            }          
          });
        }
      },
    }
  };
  </script>

<style scoped>
.sub-section{
  border-style: solid;
  border-color: #e1e1e1;
}
</style>